<template>
  <div class="sectionModel">
    <div class="tips">{{$t('main.model_hint_title_2')}}</div>
    <div class="trans">
      {{sectionData['trans'] | textFilter}}
      <hint @hint="hintHandle"></hint>
    </div>
    <div class="questionWrap">
      <div class="questionOption" v-for="(op, i) in questionOptions" ref="op" @click="checkHandle(op, i)" :key="i">
        {{op.val}}
      </div>
    </div>
  </div>
</template>

<script>
import sectionM1_Mixin from './sectionM1'

export default {
  mixins: [ sectionM1_Mixin ]
}
</script>

<style lang="scss" scoped>
  .sectionModel {
    .trans {
      min-width: 80%;
      position: relative;
      margin-top: 15vh;
      font-size: 30px;
      color: #2F2F4D;
      .hint {
        position: absolute;
        top: -1.5px;
        right: 0;
      }
    }
    .questionWrap {
      margin-top: 20vh;
      padding: 0;
      background: transparent;
      justify-content: center;
      .questionOption {
        margin: 20px;
        width: 164px;
        height: 170px;
        line-height: 170px;
        border-radius: 26px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .sectionModel {
      .questionWrap {
        justify-content: unset;
        .questionOption {
          margin: 4%;
          padding: 0 4%;
          width: 42%;
          height: 38vw;
          line-height: 38vw;
        }
      }
    }
  }
</style>