<template>
  <div class="sectionModel">
    <div class="tips">{{$t('main.model_hint_title_5')}}</div>
    <fvideo :videoUrl="videoUrl"></fvideo>
    <div class="sent" v-html="questionSent"></div>
    <div class="questionWrap character">
      <hint @hint="hintHandle"></hint>
      <div class="questionOption" v-for="(op, i) in questionOptions" ref="op" @click="checkHandle(op, i)" :key="i">
        {{op}}
      </div>
    </div>
  </div>
</template>

<script>
import modelMixins from '@/mixins/clipsModelMixins'

export default {
  mixins: [ modelMixins ],
  data() {
    return {
      questionSent: '',
      questionOptions: [],
      answerList: [],
      wrongCount: 0
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    wrongCount(val) {
      if (val === 2) {
        this.$emit('wrong')
      }
    }
  },
  methods: {
    init() {
      let { sent, stem, op, an } = this.sectionData
      let questionSent = ''
      let answerList = []
      let questionOptions = []
      let anIndex = 0

      for (let i = 0; i < stem.length; i ++) {
        let stemStr = stem[i]
        if (stemStr === this.underline) {
          let currentAn = an[anIndex]
          let currentOp = op[anIndex]
          for (let j = 0; j< currentAn.length; j++) {
            if (currentAn[j] === '_') {
              answerList.push(currentOp[j])
              questionOptions.push(currentOp[j])
            }
          }
          let sentIndex = sent.toLowerCase().indexOf(currentOp.toLowerCase())
          questionSent += sent.slice(0, sentIndex) + currentAn.replace(/\_/g, '<i class="underline">_</i>')
          sent = sent.slice(sentIndex + currentAn.length)
          anIndex ++
        } else {
          let sentIndex = sent.toLowerCase().indexOf(stemStr.toLowerCase())
          questionSent += sent.slice(0, sentIndex + stemStr.length)
          sent = sent.slice(sentIndex + stemStr.length)
        }
      }

      this.questionSent = questionSent
      this.answerList = answerList
      this.questionOptions = questionOptions.sort(() => Math.random() - 0.5)
    },
    checkHandle(op, index) {
      let currentDom = this.$refs.op[index]
      if (!currentDom.classList.contains('selected')) {
        if (op === this.answerList[0]) {
          currentDom.classList.add('right')
          currentDom.classList.add('selected')
          this.questionSent = this.questionSent.replace('<i class="underline">_</i>', op)
          this.answerList.shift()
          if (!this.answerList.length) {
            setTimeout(() => {
              this.$emit('correct')
            }, 500)
          }
        } else {
          currentDom.classList.add('wrong')
          this.wrongCount ++
          setTimeout(() => {
            currentDom.classList.remove('wrong')
          }, 500)
        }
      }
    },
    hintHandle() {
      let opDoms = this.$refs.op
      for (let opDom of opDoms) {
        if (!opDom.classList.contains('selected')) {
          let opVal = opDom.innerHTML.trim()
          if (opVal === this.answerList[0]) {
            opDom.classList.add('right')
            setTimeout(() => {
              opDom.classList.remove('right')
            }, 200)

            return
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .sectionModel {
    .questionWrap {
      margin-top: 5vh;
    }
  }
</style>