<template>
  <div class="sectionModel">
    <div class="tips">{{$t('main.model_hint_title_6')}}</div>
    <div class="trans">{{sectionData['trans'] | textFilter}}</div>
    <div class="sentWrap">
      <div class="sent" v-html="questionSent"></div>
      <div class="hintWrap">
        <hint @hint="hintHandle"></hint>
        <div class="rightAnswer" v-if="hintShow">{{rightAnswer}}</div>
      </div>
    </div>
    <keyboard
      :questionOptions="questionOptions"
      :rightAnswer="rightAnswer"
      @keydownHandle="checkHandle"
      @backspaceHandle="backspaceHandle"
      @correct="$emit('correct')"
      @wrong="wrongHandle">
    </keyboard>
  </div>
</template>

<script>
import sectionM5_Mixin from './sectionM5'

export default {
  mixins: [ sectionM5_Mixin ],
  data() {
    return {
      selectIndex: 0,
      hintShow: false
    }
  },
  computed: {
    rightAnswer() {
      return this.answerList.join('')
    }
  },
  methods: {
    checkHandle(key) {
      this.selectIndex ++
      this.questionSent = this.questionSent.replace('<i class="underline">_</i>', `<i class="char char_${this.selectIndex}">${key}</i>`)
    },
    backspaceHandle(key) {
      this.questionSent = this.questionSent.replace(`<i class="char char_${this.selectIndex}">${key}</i>`, '<i class="underline">_</i>')
      this.selectIndex --
    },
    hintHandle() {
      this.hintShow = true
      setTimeout(() => {
        this.hintShow = false
      }, 2000)
    },
    wrongHandle() {
      this.hintShow = true
      this.wrongCount ++
    }
  }
}
</script>

<style lang="scss" scoped>
  .sectionModel {
    .trans {
      margin-top: 12vh;
      font-size: 24px;
      color: #2F2F4D;
    }
    .sentWrap {
      display: flex;
      align-items: center;
      margin-top: 5vh;
      .sent {
        margin-top: unset;
        margin-left: 45px + 50px;
      }
      .hintWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 45px;
        height: 45px;
        position: relative;
        margin-left: 50px;
        .hint {
          position: unset;
          flex-shrink: 0;
        }
        .rightAnswer {
          margin-top: 1vh;
          padding: 5px 24px;
          background: rgba(0,0,0,0.02);
          border-radius: 5px;
          font-weight: 500;
          font-size: 30px;
          color: #45DBA1;
        }
      }
    }
    .keyboard {
      margin-top: 10vh;
    }
  }
</style>