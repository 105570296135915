<template>
  <div class="keyboard">
    <div class="key" :class="{active: questionOptions.includes('q')}" @click="keydownHandle('q')">q</div>
    <div class="key" :class="{active: questionOptions.includes('w')}" @click="keydownHandle('w')">w</div>
    <div class="key" :class="{active: questionOptions.includes('e')}" @click="keydownHandle('e')">e</div>
    <div class="key" :class="{active: questionOptions.includes('r')}" @click="keydownHandle('r')">r</div>
    <div class="key" :class="{active: questionOptions.includes('t')}" @click="keydownHandle('t')">t</div>
    <div class="key" :class="{active: questionOptions.includes('y')}" @click="keydownHandle('y')">y</div>
    <div class="key" :class="{active: questionOptions.includes('u')}" @click="keydownHandle('u')">u</div>
    <div class="key" :class="{active: questionOptions.includes('i')}" @click="keydownHandle('i')">i</div>
    <div class="key" :class="{active: questionOptions.includes('o')}" @click="keydownHandle('o')">o</div>
    <div class="key" :class="{active: questionOptions.includes('p')}" @click="keydownHandle('p')">p</div>

    <div class="block"></div>
    <div class="key" :class="{active: questionOptions.includes('a')}" @click="keydownHandle('a')">a</div>
    <div class="key" :class="{active: questionOptions.includes('s')}" @click="keydownHandle('s')">s</div>
    <div class="key" :class="{active: questionOptions.includes('d')}" @click="keydownHandle('d')">d</div>
    <div class="key" :class="{active: questionOptions.includes('f')}" @click="keydownHandle('f')">f</div>
    <div class="key" :class="{active: questionOptions.includes('g')}" @click="keydownHandle('g')">g</div>
    <div class="key" :class="{active: questionOptions.includes('h')}" @click="keydownHandle('h')">h</div>
    <div class="key" :class="{active: questionOptions.includes('j')}" @click="keydownHandle('j')">j</div>
    <div class="key" :class="{active: questionOptions.includes('k')}" @click="keydownHandle('k')">k</div>
    <div class="key" :class="{active: questionOptions.includes('l')}" @click="keydownHandle('l')">l</div>
    <div class="block"></div>

    <div class="key space"><svg t="1667293666970" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4420" width="32" height="32"><path d="M170.666667 384v170.666667h682.666666V384h85.333334v213.333333a42.666667 42.666667 0 0 1-42.666667 42.666667H128a42.666667 42.666667 0 0 1-42.666667-42.666667V384h85.333334z" p-id="4421"></path></svg></div>
    <div class="key" :class="{active: questionOptions.includes('z')}" @click="keydownHandle('z')">z</div>
    <div class="key" :class="{active: questionOptions.includes('x')}" @click="keydownHandle('x')">x</div>
    <div class="key" :class="{active: questionOptions.includes('c')}" @click="keydownHandle('c')">c</div>
    <div class="key" :class="{active: questionOptions.includes('v')}" @click="keydownHandle('v')">v</div>
    <div class="key" :class="{active: questionOptions.includes('b')}" @click="keydownHandle('b')">b</div>
    <div class="key" :class="{active: questionOptions.includes('n')}" @click="keydownHandle('n')">n</div>
    <div class="key" :class="{active: questionOptions.includes('m')}" @click="keydownHandle('m')">m</div>
    <div class="key backspace" :class="{active: selectList.length}" @click="backspaceHandle"><svg t="1667292273550" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3341" width="32" height="32"><path d="M845.21415111 209.07804445H334.90716445c-24.46677333 0-47.76846222 11.06830222-63.49710223 30.29219555L72.18062222 478.21255111l-0.58254222 0.58254222c-6.99050667 9.32067555-10.48576 20.97152-10.48576 33.20490667 0 12.81592889 4.66033778 25.63185778 12.81592889 36.11761778L271.41006222 785.79484445c15.72864 18.64135111 39.03032889 29.70965333 63.49710223 29.70965333h510.30698666c44.85575111 0 80.97336889-36.11761778 80.97336889-80.97336889V290.05141333c0-44.85575111-36.11761778-80.97336889-80.97336889-80.97336888zM684.43249778 623.26556445c-5.82542222 5.82542222-13.39847111 8.73813333-20.97152 8.73813333-8.15559111 0-15.72864-2.91271111-20.97152-8.73813333l-68.73998223-68.73998223-68.73998222 68.73998223c-5.82542222 5.82542222-13.39847111 8.73813333-20.97152 8.73813333-16.31118222 0-29.70965333-13.39847111-29.70965333-29.70965333 0-8.15559111 2.91271111-15.72864 8.73813333-20.97152l68.73998222-68.73998223-69.32252444-69.32252444c-11.65084445-11.65084445-11.65084445-30.29219555 0-41.94304s30.29219555-11.65084445 41.94304 0l68.73998222 68.73998222 68.73998222-68.73998222c11.65084445-11.65084445 30.29219555-11.65084445 41.94304 0.58254222a29.53443555 29.53443555 0 0 1 0 41.94304L615.69251555 512l68.73998223 68.73998222c11.65084445 11.65084445 11.65084445 30.87473778 0 42.52558223z" p-id="3342"></path></svg></div>
    <div class="keyBlock"></div>

    <div class="key check" :class="{active: !questionOptions.length && !answerOver}" @click="checkHandle">{{$t('web.check')}}</div>
  </div>
</template>

<script>
export default {
  props: ['questionOptions', 'rightAnswer'],
  data() {
    return {
      selectList: [],
      answerOver: false
    }
  },
  methods: {
    keydownHandle(key) {
      if (this.questionOptions.includes(key)) {
        this.questionOptions.splice(this.questionOptions.indexOf(key), 1)
        this.selectList.push(key)
        this.$emit('keydownHandle', key)
      }
    },
    backspaceHandle() {
      if (this.selectList.length) {
        let key = this.selectList.pop()
        this.questionOptions.push(key)
        this.$emit('backspaceHandle', key)
      }
    },
    checkHandle() {
      if (!this.questionOptions.length && !this.answerOver) {
        let userAnswer = this.selectList.join('')
        let rightAnswer = this.rightAnswer
        if (userAnswer === rightAnswer) {
          document.querySelectorAll('.char').forEach(el => el.classList.add('rightChar'))
          this.answerOver = true
          this.selectList = []
          setTimeout(() => {
            this.$emit('correct')
          }, 800)
        } else {
          document.querySelectorAll('.char').forEach(el => el.classList.add('wrongChar'))
          this.$emit('wrong')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .keyboard {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 738px;
    background: rgba(0,0,0,0.02);
    border-radius: 20px;
    padding: 4%;
    .block {
      width: 2%;
      aspect-ratio: 1;
    }
    .keyBlock {
      box-sizing: border-box;
      width: 9%;
      aspect-ratio: 1;
      margin: 0.5%;
      border: 2px solid transparent;
    }
    .key {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 9%;
      aspect-ratio: 1;
      margin: 0.5%;
      border: 2px solid rgba(89,124,186,0.16);
      border-radius: 6px;
      background: #FFFFFF;
      font-weight: 400;
      font-size: 18px;
      color: #8E9FC1;
      &.space, &.backspace {
        svg {
          path {
            fill: #BAC8E1;
          }
        }
      }
      &.check {
        width: 100%;
        height: 58px;
      }
      &.active {
        border: 2px solid #004E94;
        color: #004E94;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background: #DCE4F2;
        }
        svg {
          path {
            fill: #004E94;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .keyboard {
      .key {
        font-size: 12px;
        &.space, &.backspace {
          svg {
            width: 18px;
            height: 18px;
          }
        }
        &.check {
          width: 100%;
          height: 30px;
        }
      }
    }
  }
</style>