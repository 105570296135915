<template>
  <div class="sectionModel">
    <div class="tips">{{$t('main.model_hint_title_7')}}</div>
    <fvideo :videoUrl="videoUrl"></fvideo>
    <div class="questionWrap">
      <hint @hint="hintHandle"></hint>
      <div class="questionOption" v-for="(op, i) in questionOptions" ref="op" @click="checkHandle(op, i)" :key="i">
        {{op.val}}
      </div>
    </div>
  </div>
</template>

<script>
import sectionM1_Mixin from './sectionM1'

export default {
  mixins: [ sectionM1_Mixin ]
}
</script>