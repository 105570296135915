<template>
  <div class="clipsLesson">
    <template v-if="!finish">
      <div class="lessonWrap">
        <div class="lessonHeader">
          <div class="close" @click="closeHandle">
            <img src="@/assets/images/clips/lesson/close.png" alt="close">
          </div>
          <div class="progress">
            <div class="progressBar" :style="{width: progressVal}"></div>
          </div>
        </div>

        <div class="lessonMain" ref="scrollArea">
          <component
            :is="currentModel"
            :dialoguesData="dialoguesData"
            :sectionData="currentSection"
            :sectionIndex="sectionIndex"
            :firstVideoLearn="firstVideoLearn"
            :key="sectionIndex"
            @goNext="sectionIndex++"
            @goPre="sectionIndex--"
            @correct="correctHandle"
            @wrong="wrongHandle"
            @finish="finishHandle"
          />
        </div>
      </div>
    </template>

    <template v-else>
      <finish
        :medalCount="medalCount"
        :earnXP="earnXP"
        :lessonSents="lessonSents">
      </finish>
    </template>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import constants from '@/utils/constants'
  import preLoadUtil from '@/utils/preLoadUtil'
  import { updateHistoryData, updateClipsProgressData } from '@/utils/dataUpdateUtil'
  import { getLessonData } from '@/api/clips'
  import videoLearn from '@/components/clips/models/videoLearn.vue'
  import sectionM1 from '@/components/clips/models/sectionM1.vue'
  import sectionM2 from '@/components/clips/models/sectionM2.vue'
  import sectionM3 from '@/components/clips/models/sectionM3.vue'
  import sectionM4 from '@/components/clips/models/sectionM4.vue'
  import sectionM5 from '@/components/clips/models/sectionM5.vue'
  import sectionM6 from '@/components/clips/models/sectionM6.vue'
  import sectionM7 from '@/components/clips/models/sectionM7.vue'
  import dialogueModel from '@/components/clips/models/dialogueModel.vue'
  import finish from '@/components/clips/Finish.vue'
  import learnTimeMixins from '@/mixins/learnTimeMixins'

  // 忽略的题型：M8暂未出题，语音题暂时不做
  const SkipModels = ['8', '9', '10', '11']

  export default {
    mixins: [ learnTimeMixins ],
    data() {
      return {
        id: '',
        lessonSents: [],
        totalCount: 0,
        wrongCount: 0,
        sectionsData: [],
        dialoguesData: [],
        currentModel: '',
        currentSection: {},
        currentDialogue: {},
        sectionIndex: 0,
        dialogueIndex: 0,
        firstVideoLearn: true,
        finish: false,
        medalCount: 0,
        earnXP: 0
      }
    },
    components: {
      finish,
      videoLearn,
      sectionM1,
      sectionM2,
      sectionM3,
      sectionM4,
      sectionM5,
      sectionM6,
      sectionM7,
      dialogueModel
    },
    computed: {
      ...Vuex.mapGetters(['clipsLessonInfo', 'userLanguage', 'learnLanguage']),
      progressVal() {
        let progess = 0
        let total = this.sectionsData.length
        if (total > 0) {
          let current = this.sectionIndex + 1
          progess = ((current / total) * 100).toFixed(2) + '%'
        }
        return progess
      }
    },
    watch: {
      sectionIndex(index) {
        if (index < this.sectionsData.length) {
          let currentSection = this.sectionsData[index]
          this.currentSection = currentSection
          this.currentModel = this.getModel(currentSection)
          this.firstVideoLearn = false
  
          // 标记出每一段的第一个视频学习题
          if (currentSection['type'] === 1) {
            if (index === 0 || this.sectionsData[index - 1]['type'] !== 1) {
              this.firstVideoLearn = true
            }
          }
        } else {
          // 前两章没有dialog
          if (['1', '2'].includes(this.id)) {
            this.finishHandle()
          } else {
            this.currentModel = 'dialogueModel'
            this.$refs.scrollArea.classList.add('dialogue')
          }
        }
      }
    },
    created() {
      this.initSections()
    },
    methods: {
      async initSections() {
        if (this.clipsLessonInfo) {
          let loading = this.$loading()
          this.id = this.clipsLessonInfo.lsID
          this.lessonSents = this.clipsLessonInfo.lsSents

          let lessonData = await getLessonData(this.userLanguage, this.learnLanguage, this.id)
          this.totalCount = this.getTotalCount(lessonData)
          this.sectionsData = this.deleteSkipModels(lessonData.sections)
          this.dialoguesData = lessonData.dialogues
          
          this.$nextTick(async function() {
            await this.preloadAssets()
            if (this.clipsLessonInfo.type && this.clipsLessonInfo.type === 'continue') {
              let lessonProgress = this.clipsLessonInfo.lessonProgress
              let currentProgress = lessonProgress.split('/')[0]
              let sectionIndex = Number(currentProgress)
              if (sectionIndex > this.sectionsData.length - 1) {
                this.sectionIndex = this.sectionsData.length - 1
              } else {
                this.sectionIndex = sectionIndex
              }
            }
            this.currentSection = this.sectionsData[this.sectionIndex]
            this.currentModel = this.getModel(this.currentSection)
            this.timingStart()
            loading.close()
          })
        } else {
          this.$router.push('/clips')
        }
      },
      async preloadAssets() {
        await this.preloadVideos()
        await this.preloadDialogAudios()
      },
      async preloadVideos() {
        let videoList = []
        let videoUrl = constants.clipsMediaUrl.replace('LANG', this.learnLanguage)
        let langParam = constants.langsData[this.learnLanguage]['clipsMediaName']
        for (let section of this.sectionsData) {
          if (section.type === 1) {
            videoList.push(videoUrl + `${langParam}-ls-${section.id}.mp4`)
          } else {
            videoList.push(videoUrl + `${langParam}-ps-${section.id}.mp4`)
          }
        }
        await preLoadUtil.asyncPreloadSource(videoList)
      },
      async preloadDialogAudios() {
        if (!['1', '2'].includes(this.id)) {
          let audioList = []
          let videoUrl = constants.clipsMediaUrl.replace('LANG', this.learnLanguage)
          let langParam = constants.langsData[this.learnLanguage]['clipsMediaName']
          for (let dialogue of this.dialoguesData) {
            let audioURL = videoUrl + `${langParam}-f-ds-${dialogue.id}.mp3`
            audioList.push(audioURL)
          }
          await preLoadUtil.asyncPreloadSource(audioList)
        }
      },
      getTotalCount(lessonData) {
        // ----- 获取总题数（视频学习也算） -----
        // 前两课（id为1、2）没有dialog和speaking => 总数 +0
        // 第三四五课（id为3、4、5）没有speaking => 总数 +1
        // 其他课都有 => 总数 +2
        // web端不做语音题和speaking，遇到算做对

        let totalCount = 0
        let sectionsCount = lessonData.sections.length
        if (['1', '2'].includes(this.id)) {
          totalCount = sectionsCount
        } else if (['3', '4', '5'].includes(this.id)) {
          totalCount = sectionsCount + 1
        } else {
          totalCount = sectionsCount + 2
        }

        return totalCount
      },
      getModel(data) {
        let model = ''
        if (data.type === 1) {
          model = 'videoLearn'
        } else if (data.type === 2) {
          model = 'sectionM' + data.model
        }
        return model
      },
      correctHandle() {
        setTimeout(() => {
          this.sectionIndex ++
        }, 300);
      },
      wrongHandle() {
        this.wrongCount ++
      },
      closeHandle() {
        this.updateData()
        this.$router.push('/clips')
      },
      finishHandle() {
        this.getEarnVal()
        this.updateData('finish')
        this.finish = true
      },

      // 更新数据
      updateData(flag) {
        this.timingStop()

        let progress = this.sectionIndex + '/' + this.totalCount
        if (flag && flag === 'finish') {
          progress = this.totalCount + '/' + this.totalCount
        }
        updateHistoryData(this.duration, this.earnXP)
        updateClipsProgressData(this.id, this.medalCount, progress)
      },
      // 计算获得的xp和徽章数
      getEarnVal() {
        let totalCount = this.totalCount
        let wrongCount = this.wrongCount
        let rightCount = totalCount - wrongCount
        let correctRate = rightCount / totalCount
        let medalCount = 0

        if (correctRate < 0.3) {
          medalCount = 1
        } else if (correctRate < 0.7) {
          medalCount = 2
        } else {
          medalCount = 3
        }

        this.medalCount = medalCount
        this.earnXP = Math.trunc(correctRate * 30)
      },
      // 删除忽略的题型
      deleteSkipModels(sections) {
        let sectionsData = sections.filter(item => {
          return !SkipModels.includes(item.model)
        })
        // 复习模式删除视频学习题型
        if (this.clipsLessonInfo.type && this.clipsLessonInfo.type === 'review') {
          sectionsData = sectionsData.filter(item => {
            return item.type !== 1
          })
        }
        return sectionsData
      }
    }
  }
</script>

<style lang="scss" scoped>
  .clipsLesson {
    background: #F3F4F8;
    .lessonWrap {
      width: 90vw;
      max-width: 960px;
      margin: 0 auto;
      .lessonHeader {
        box-sizing: border-box;
        width: 100%;
        height: 30px;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .close {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          cursor: pointer;
          img {
            width: 17px;
          }
        }
        .progress {
          position: relative;
          width: calc(100% - 35px);
          height: 12px;
          border-radius: 12px;
          background: #D8D8D8;
          overflow: hidden;
          &Bar {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            height: 100%;
            border-radius: 12px;
            background: #17DAC5;
            transition: width .5s;
          }
        }
      }
      .lessonMain {
        width: 100%;
        height: calc(100vh - 30px);
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        &::-webkit-scrollbar {
          width: 0;
        }
        &.dialogue {
          box-sizing: border-box;
          padding-bottom: 20vh;
        }
      }
    }
  }
</style>