<template>
  <div class="dialogue">
    <div class="stemWrap">
      <div class="headWrap">
        <img class="headImg" :src="headImage" alt="header">
      </div>
      <div class="sentWrap">
        <div class="cornerWrap">
          <div class="corner"></div>
        </div>
        <div class="sent">
          <div class="audio lock" ref="dialogueAudio"></div>
          {{dialogueData['sent']}}
        </div>
        <div class="trans">{{dialogueData['trans']}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['dialogueData', 'audio', 'audioIndex', 'headImage'],
  mounted() {
    this.$emit('playAudio', this.audioIndex)
  },
  watch: {
    'audio.playState': async function(val) {
      if (val === 'start' && this.audioIndex === this.audio.index) {
        this.$refs.dialogueAudio.classList.remove('lock')
        this.$refs.dialogueAudio.classList.add('active')
      } else {
        this.$refs?.dialogueAudio?.classList?.remove('active')
      }
    }
  }
}
</script>