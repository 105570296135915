<template>
  <div class="dialogueModel">
    <div class="tips">{{$t('main.complete_the_dialogue')}}</div>

    <template v-for="(dialogue, i) in dialogues">
      <component
        :is="dialogueModel(dialogue.model)"
        :key="i"
        :class="{reverse: dialogue.mf !== dialogues[0].mf}"
        :dialogueData="dialogue"
        :audioIndex="i"
        :audio="audio"
        :headImage="getHeadImage(dialogue.mf)"
        @playAudio="playAudioHandle"
      />
    </template>

    <div class="finishWrap" v-if="finishFlag">
      <div class="finishBtn" @click="finishHandle">{{$t('main.finish')}}</div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import constants from '@/utils/constants'
import Audio from '@/utils/audioUtil'
import '@/assets/styles/clips/dialogueModel.scss'
import dialogueM0 from './dialogueM0.vue'
import dialogueM4 from './dialogueM4.vue'
import dialogueM6 from './dialogueM6.vue'

export default {
  data() {
    return {
      dialogues: [],
      dialogueIndex: 0,
      audio: null,
      finishFlag: false
    }
  },
  props: ['dialoguesData'],
  components: {
    dialogueM0,
    dialogueM4,
    dialogueM6,
  },
  computed: {
    ...Vuex.mapGetters(['learnLanguage'])
  },
  mounted() {
    this.loadAudios()
    this.init()
  },
  watch: {
    dialogueIndex(index) {
      this.$nextTick(function() {
        // 让页面始终在最底部
        let scrollArea = document.querySelector('.lessonMain')
        scrollArea.scrollTop = scrollArea.scrollHeight
      })
      if (index < this.dialoguesData.length) {
        this.init()
      } else {
        this.finishFlag = true
      }
    },
    'audio.playState': function(val) {
      if (val === 'end' && this.dialogueIndex === this.audio.index) {
        this.dialogueIndex ++
      }
    }
  },
  methods: {
    init() {
      let currentDialogue = this.dialoguesData[this.dialogueIndex]
      this.dialogues.push(currentDialogue)
    },
    loadAudios() {
      let dialoguesData = this.dialoguesData
      let audios = []
      let videoUrl = constants.clipsMediaUrl.replace('LANG', this.learnLanguage)
      let langParam = constants.langsData[this.learnLanguage]['clipsMediaName']
      for (let dialogue of dialoguesData) {
        let audioUrl = videoUrl + `${langParam}-f-ds-${dialogue.id}.mp3`
        audios.push({ audioUrl })
      }
      this.audio = new Audio(audios)
    },
    playAudioHandle(index) {
      this.audio.play(index)
    },
    dialogueModel(model) {
      model = model === '3' ? '4' : model
      model = model === '5' ? '6' : model
      return 'dialogueM' + model
    },
    getHeadImage(mf) {
      return require(`@/assets/images/clips/lesson/${mf === '1' ? 'man': 'woman'}.png`)
    },
    finishHandle() {
      this.$emit('finish')
    }
  }
}
</script>

<style lang="scss" scoped>
  .dialogueModel {
    width: 100%;
    max-width: 800px;
    min-height: 100%;
    .tips {
      margin: 40px auto 20px;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      color: #9090A2;
    }
    .finishWrap {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 20vh;
      background: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      .finishBtn {
        padding: 0 20px;
        min-width: 220px;
        height: 46px;
        line-height: 46px;
        border-radius: 46px;
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
        background: #004E94;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          background: #0A4172;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .dialogueModel {
      .tips {
        margin: 20px auto;
        font-size: 14px;
      }
    }
  }
</style>