import request from '../utils/request'
import constants from '../utils/constants'
import EncryptDataUtil from '../utils/encryptDataUtil.js'

export async function getUnitsData(userLang, learnLang) {
  if (userLang === 'cn') userLang = 'tch'
  let unitsDataUrl = constants.clipsUnitsSourceUrl.replace('USERLANG', userLang).replace('LEARNLANG', learnLang)
  let res = await request({
    url: unitsDataUrl,
    method: 'GET',
  })
  let unitsData = res.data
  
  return unitsData
}

export async function getLessonData(userLang, learnLang, lessonID) {
  if (userLang === 'cn') userLang = 'tch'
  let lessonDataUrl = constants.clipsLessonSourceUrl.replace('USERLANG', userLang).replace('LEARNLANG', learnLang).replace('LESSONID', lessonID)
  let res = await request({
    url: lessonDataUrl,
    method: 'GET',
  })
  let lessonData = EncryptDataUtil.decryptData(res.data)
  lessonData = JSON.parse(lessonData)
  
  return lessonData
}