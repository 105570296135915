<template>
  <div class="finish">
    <div class="xpWrap">
      <div class="cupWrap">
        <img class="cupImg" src="@/assets/images/clips/lesson/cup.png" alt="cup">
        <div class="earnXP">+ {{earnXP}} {{$t('main.experience')}}</div>
      </div>
      <div class="medalWrap">
        <img class="medalImg" src="@/assets/images/clips/lesson/medal.png" alt="medal">

        <img v-if="medalCount < 2" class="medalImg" src="@/assets/images/clips/lesson/medalUnlock.png" alt="medal">
        <img v-else class="medalImg" src="@/assets/images/clips/lesson/medal.png" alt="medal">

        <img v-if="medalCount < 3" class="medalImg" src="@/assets/images/clips/lesson/medalUnlock.png" alt="medal">
        <img v-else class="medalImg" src="@/assets/images/clips/lesson/medal.png" alt="medal">
      </div>
    </div>
    <div class="audioWrap">
      <template v-for="(sentObj, i) in lessonSents">
        <div class="sentWrap" @click="playAudioHandle(i)" :key="i">
          <div class="sent">
            <div class="audio" :class="{active: audioPlaying && audioIndex === i}"></div>
            {{sentObj.sent | textFilter}}
          </div>
          <div class="trans">{{sentObj.trans | textFilter}}</div>
        </div>
      </template>
      <div class="continueBtn" @click="continueClickHandle">{{$t('web.clips_continue')}}</div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import constants from '@/utils/constants'
import Audio from '@/utils/audioUtil'

export default {
  props: ['lessonSents', 'earnXP', 'medalCount'],
  data() {
    return {
      audio: null,
      audioIndex: -1,
      audioPlaying: false
    }
  },
  mounted() {
    this.loadAudios()
  },
  computed: {
    ...Vuex.mapGetters(['learnLanguage'])
  },
  watch: {
    'audio.playState': function(val) {
      if (val === 'start') {
        this.audioPlaying = true
      } else {
        this.audioPlaying = false
      }
    }
  },
  filters: {
    textFilter(val) {
      return val.replace(/!@@@!/g, '\n')
    }
  },
  methods: {
    loadAudios() {
      let lessonSents = this.lessonSents
      let audios = []
      let videoUrl = constants.clipsMediaUrl.replace('LANG', this.learnLanguage)
      let langParam = constants.langsData[this.learnLanguage]['clipsMediaName']
      for (let lessonObj of lessonSents) {
        let audioUrl = videoUrl + `${langParam}-ps-${lessonObj.id}.mp4`
        audios.push({ audioUrl })
      }
      this.audio = new Audio(audios)
    },
    playAudioHandle(index) {
      this.audio.play(index)
      this.audioIndex = index
    },
    continueClickHandle() {
      this.$router.push('/clips')
    }
  }
}
</script>

<style lang="scss" scoped>
  .finish {
    width: 100%;
    min-height: 100vh;
    padding: 20px 0;
    background: #F3F4F8;
    .xpWrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90vw;
      max-width: 800px;
      margin: 0 auto;
      border-radius: 12px;
      background-color: #FFFFFF;
      background-image: linear-gradient(0, #4a8efe00 20%, #004E94 100%);
      .cupWrap {
        position: relative;
        width: 343px;
        height: 199px;
        .cupImg {
          width: 100%;
          height: 100%;
        }
        .earnXP {
          position: absolute;
          left: 0;
          top: 155px;
          width: 100%;
          height: 44px;
          line-height: 44px;
          text-align: center;
          font-weight: 600;
          font-size: 22px;
          color: #FFFFFF;
        }
      }
      .medalWrap {
        display: flex;
        justify-content: space-between;
        margin: 32px 0;
        width: 200px;
        height: 47px;
        .medalImg {
          width: 36px;
          height: 100%;
        }
      }
    }
    .audioWrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90vw;
      max-width: 800px;
      margin: 24px auto 0;
      padding: 10px 0;
      border-radius: 12px;
      background-color: #FFFFFF;
      .sentWrap {
        width: 100%;
        max-width: 560px;
        padding: 16px;
        border-radius: 10px;
        border-bottom: 1px solid #2F2F4D14;
        box-sizing: border-box;
        transition: all 0.3s;
        &:hover {
          cursor: pointer;
          background: #2F2F4D14;
        }
        .sent {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 18px;
          color: #2F2F4D;
          white-space: pre-line;
          .audio {
            margin-right: 10px;
            width: 24px;
            height: 24px;
            background-image: url('~@/assets/images/courses/audio.png');
            background-repeat: no-repeat;
            background-size: 72px;
            background-position: -48px 0;
            cursor: pointer;
            &.active {
              animation: audio 0.6s infinite steps(3);
              @keyframes audio {
                0% {
                  background-position: 0 0;
                }
                100% {
                  background-position: -72px 0;
                }
              }
            }
          }
        }
        .trans {
          margin-top: 6px;
          margin-left: 34px;
          font-weight: 500;
          font-size: 16px;
          color: #4F5072;
          white-space: pre-line;
        }
      }
      .continueBtn {
        margin: 34px 0;
        width: 268px;
        height: 46px;
        line-height: 46px;
        border-radius: 46px;
        background: #004E94;
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
        transition: all 0.3s;
        &:hover {
          cursor: pointer;
          background: #0A4172;
        }
      }
    }
  }
</style>