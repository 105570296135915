import Vuex from 'vuex'
import constants from '@/utils/constants'
import fvideo from '@/components/clips/FVideo'
import hint from '@/components/Hint'
import keyboard from '@/components/clips/Keyboard'
import '@/assets/styles/clips/sectionModel.scss'

const modelMixins = {
  data() {
    return {
      videoUrl: '',
      underline: constants.underline
    }
  },
  props: ['sectionData', 'sectionIndex'],
  components: {
    fvideo,
    hint,
    keyboard
  },
  computed: {
    ...Vuex.mapGetters(['learnLanguage'])
  },
  filters: {
    textFilter(val) {
      return val.replace(/!@@@!/g, '\n')
    }
  },
  watch: {
    sectionData() {
      this.initVideo()
    }
  },
  mounted() {
    this.initVideo()
  },
  methods: {
    initVideo() {
      let videoUrl = constants.clipsMediaUrl.replace('LANG', this.learnLanguage)
      let langParam = constants.langsData[this.learnLanguage]['clipsMediaName']
      if (this.sectionData.type === 1) {
        this.videoUrl = videoUrl + `${langParam}-ls-${this.sectionData['id']}.mp4`
      } else {
        this.videoUrl = videoUrl + `${langParam}-ps-${this.sectionData['id']}.mp4`
      }
    }
  }
}

export default modelMixins