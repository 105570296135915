<template>
  <div class="videoWrap">
    <video
      class="video"
      ref="video"
      :src="videoUrl"
      @play="playStatus = false"
      @pause="playStatus = true"
      playsinline="true"
      webkit-playsinline="true"
      autoplay>
    </video>
    <div class="controlsWrap" v-show="playStatus">
      <div class="slowPlayArea playArea" @click="slowPlayHandle">
        <img class="slowPlayIcon playIcon" src="@/assets/images/clips/lesson/slow.png" alt="slowPlay">
      </div>
      <div class="normalPlayArea playArea" @click="normalPlayHandle">
        <img class="normalPlayIcon playIcon" src="@/assets/images/clips/lesson/play.png" alt="normalPlay">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        playStatus: false
      }
    },
    props: ['videoUrl'],
    watch: {
      videoUrl() {
        this.playStatus = false
      }
    },
    methods: {
      slowPlayHandle() {
        this.$refs.video.playbackRate = 0.5
        this.$refs.video.play()
      },
      normalPlayHandle() {
        this.$refs.video.playbackRate = 1
        this.$refs.video.play()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .videoWrap {
    position: relative;
    display: flex;
    margin-top: 3.4vh;
    width: 90vw;
    max-width: 535px;
    height: 300px;
    border-radius: 20px;
    background: #131313;
    overflow: hidden;
    .lottie {
      position: absolute;
      left: calc(50% - 50px);
      top: calc(50% - 50px);
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .controlsWrap {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      cursor: pointer;
      .playArea {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
      }
      .playIcon {
        top: calc((300px - 4.5rem) / 2);
        width: 55px;
        height: 55px;
        &.slowPlayIcon {
          margin-left: 60%;
        }
        &.normalPlayIcon {
          margin-right: 60%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .videoWrap {
      height: unset;
    }
  }
</style>