<template>
  <div class="sectionModel videoLearn">
    <div class="tips">{{sectionData.tips}}</div>
    <fvideo :videoUrl="videoUrl"></fvideo>
    <div class="sent">{{sectionData['sent'] | textFilter}}</div>
    <div class="trans">{{sectionData['trans'] | textFilter}}</div>
    <div class="btnWrap" :class="{firstVideoLearn}">
      <div class="preBtn btn" @click="$emit('goPre')">{{$t('web.clips_back')}}</div>
      <div class="nextBtn btn" @click="$emit('goNext')">{{$t('web.clips_continue')}}</div>
    </div>
  </div>
</template>

<script>
import modelMixins from '@/mixins/clipsModelMixins'

export default {
  mixins: [ modelMixins ],
  props: ['firstVideoLearn']
}
</script>

<style lang="scss" scoped>
  .videoLearn {
    .btnWrap {
      width: 100%;
      max-width: 580px;
      margin-top: 5vh;
      display: flex;
      justify-content: space-between;
      .btn {
        width: 260px;
        max-width: 48%;
        height: 46px;
        line-height: 46px;
        border-radius: 46px;
        text-align: center;
        box-sizing: border-box;
        font-weight: 700;
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s;
      }
      .preBtn {
        border: 2px solid #004E94;
        background: #FFFFFF;
        line-height: 42px;
        color: #004E94;
        &:hover {
          border: 2px solid #0A4172;
          color: #0A4172;
        }
      }
      .nextBtn {
        background: #004E94;
        color: #FFFFFF;
        &:hover {
          background: #0A4172;
        }
      }
      &.firstVideoLearn {
        justify-content: center;
        .preBtn {
          display: none;
        }
      }
    }
  }
</style>