<template>
  <div class="sectionModel">
    <div class="tips">{{$t('main.model_hint_title_1')}}</div>
    <fvideo :videoUrl="videoUrl"></fvideo>
    <div class="questionWrap">
      <hint @hint="hintHandle"></hint>
      <div class="questionOption" v-for="(op, i) in questionOptions" ref="op" @click="checkHandle(op, i)" :key="i">
        {{op.val}}
      </div>
    </div>
  </div>
</template>

<script>
import modelMixins from '@/mixins/clipsModelMixins'

export default {
  mixins: [ modelMixins ],
  data() {
    return {
      questionOptions: [],
      answer: {},
      wrongCount: 0
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    wrongCount(val) {
      if (val === 2) {
        this.$emit('wrong')
      }
    }
  },
  methods: {
    init() {
      let questionOptions = []
      let { op, an } = this.sectionData
      let opArr = op.split(';')
      for (let opStr of opArr) {
        let opKeyVal =  opStr.split('=')
        let opKey = opKeyVal[0]
        let opVal = opKeyVal[1]
        if (opVal !== '') {
          let questionOption = { key: opKey, val: opVal }
          questionOptions.push(questionOption)
          if (opKey === an) {
            this.answer = { key: an, val: opVal }
          }
        }
      }
      this.questionOptions = questionOptions.sort(() => Math.random() - 0.5)
    },
    checkHandle(op, index) {
      let currentDom = this.$refs.op[index]
      if (op.key === this.answer.key) {
        currentDom.classList.add('right')
        setTimeout(() => {
          currentDom.classList.remove('right')
          this.$emit('correct')
        }, 500)
      } else {
        currentDom.classList.add('wrong')
        this.wrongCount ++
        setTimeout(() => {
          currentDom.classList.remove('wrong')
        }, 500)
      }
    },
    hintHandle() {
      let opDoms = this.$refs.op
      for (let opDom of opDoms) {
        let opVal = opDom.innerHTML.trim()
        if (opVal === this.answer.val) {
          opDom.classList.add('right')
          setTimeout(() => {
            opDom.classList.remove('right')
          }, 200)
        }
      }
    }
  }
}
</script>