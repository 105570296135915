<template>
  <div class="dialogue">
    <div class="stemWrap">
      <div class="headWrap">
        <img class="headImg" :src="headImage" alt="header">
      </div>
      <div class="sentWrap">
        <div class="cornerWrap">
          <div class="corner"></div>
        </div>
        <div class="sent">
          <div class="audio lock" ref="dialogueAudio"></div>
          <div v-html="questionSent"></div>
        </div>
        <div class="trans">{{dialogueData['trans']}}</div>
      </div>
    </div>
    <div class="questionWrap" :class="{hidden: correct}">
      <div class="questionOption" v-for="(op, i) in questionOptions" ref="op" @click="checkHandle(op, i)" :key="i">
        {{op}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      correct: false,
      questionSent: '',
      questionOptions: [],
      answerList: [],
      sentAnswerList: []
    }
  },
  props: ['dialogueData', 'audio', 'audioIndex', 'headImage'],
  mounted() {
    this.init()
  },
  watch: {
    'audio.playState': async function(val) {
      if (val === 'start' && this.audioIndex === this.audio.index) {
        this.$refs.dialogueAudio.classList.remove('lock')
        this.$refs.dialogueAudio.classList.add('active')
      } else {
        this.$refs?.dialogueAudio?.classList?.remove('active')
      }
    }
  },
  methods: {
    init() {
      let { sent, stem, op, an } = this.dialogueData
      let questionSent = ''
      let sentAnswerList = []
      let anIndex = 0

      for (let i = 0; i < stem.length; i ++) {
        let stemStr = stem[i]
        if (stemStr === '_____') {
          let currentAn = an[anIndex]
          let sentIndex = sent.toLowerCase().indexOf(currentAn.toLowerCase())

          // 获取答案列表对应的原文列表
          let sentAnswer = sent.slice(sentIndex, sentIndex + currentAn.length)
          sentAnswerList.push(sentAnswer)

          questionSent += sent.slice(0, sentIndex) + '<i class="underline">_____</i>'
          sent = sent.slice(sentIndex + currentAn.length)
          anIndex ++
        } else {
          let sentIndex = sent.toLowerCase().indexOf(stemStr.toLowerCase())
          questionSent += sent.slice(0, sentIndex + stemStr.length)
          sent = sent.slice(sentIndex + stemStr.length)
        }
      }

      this.questionSent = questionSent
      this.questionOptions = op.sort(() => Math.random() - 0.5)
      this.answerList = an
      this.sentAnswerList = sentAnswerList
    },
    checkHandle(op, index) {
      let currentDom = this.$refs.op[index]
      if (!currentDom.classList.contains('selected')) {
        if (op === this.answerList[0]) {
          currentDom.classList.add('right')
          currentDom.classList.add('selected')
          setTimeout(() => {
            currentDom.classList.add('lock')
          }, 500)
          this.questionSent = this.questionSent.replace('<i class="underline">_____</i>', this.sentAnswerList[0])
          this.answerList.shift()
          this.sentAnswerList.shift()
          if (!this.answerList.length) {
            setTimeout(() => {
              this.correct = true
              this.$emit('playAudio', this.audioIndex)
            }, 800)
          }
        } else {
          currentDom.classList.add('wrong')
          setTimeout(() => {
            currentDom.classList.remove('wrong')
          }, 500)
        }
      }
    },
    playAudioHandle() {
      if (this.correct) {
        this.$emit('playAudio', this.audioIndex)
      }
    }
  }
}
</script>