<template>
  <div class="sectionModel">
    <div class="tips">{{$t('main.model_hint_title_4')}}</div>
     <div class="trans">{{sectionData['trans'] | textFilter}}</div>
    <div class="sent" v-html="questionSent"></div>
    <div class="questionWrap word">
      <hint @hint="hintHandle"></hint>
      <div class="questionOption" v-for="(op, i) in questionOptions" ref="op" @click="checkHandle(op, i)" :key="i">
        {{op}}
      </div>
    </div>
  </div>
</template>

<script>
import sectionM3_Mixin from './sectionM3'

export default {
  mixins: [ sectionM3_Mixin ]
}
</script>

<style lang="scss" scoped>
  .sectionModel {
    .trans {
      margin-top: 12vh;
      font-size: 24px;
      color: #2F2F4D;
    }
    .sent {
      margin-top: 10vh;
    }
  }
</style>